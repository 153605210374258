import React, { memo, useEffect, useState } from 'react';
import './css/mobileFooter.scss';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  getLocalStorage,
  setLocalStorage,
  log,
  MEDWIKI_NAME,
  reactHtmlParser,
  isIosApp,
  convertAllMenu,
  changeUrl
} from '../../common/common';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  ChannelLogo,
  DashboardLogo,
  ClinicalVideoLogo,
  LiveCmeLogo,
  SpqLogo,
  MedwikiLogo,
  EbookLogo,
  TelemedLiteLogo,
  DiscussReferLogo,
  Grlogo,
  ClirnetFullLogo,
  ClirnetIconLogo,
  TrainingLogo,
  HelpAndSupport,
  DoctubeLogo,
  DiagnosisIcon
} from '../../components/icons/menuIconComponents';

import useRedirect from '../../components/customHooks/useRedirect';
import { useDispatch, useSelector } from 'react-redux';
import packageJson from '../../../package.json';
import {
  getUserDetails,
  postMenu,
  toggleFormFocus,
  toggleGlobalModal,
  toggleMoreMobile
} from '../../Store/actions/commonActions';
import { unionWidthDefinedSpecs } from '../../pages/medwiki/SpecialityArray';
import { DEFAULT_MENU } from '../../Store/constants/app.constants';
import { routeNames } from '../../router/constants';
import {
  Cpd,
  GetSupport,
  NewSupport,
  HomeNew,
  Khub,
  LogoutNew,
  Products,
  ProfileNew,
  Resourses
} from '../icons';
import RedirectModal from '../modals/RedirectModal';
import { Nav } from 'react-bootstrap';

const { version } = packageJson;
function MobileFooter({ current_menu }) {
  const [menuJson, setMenuJson] = useState(DEFAULT_MENU);
  // getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.menu) : ''
  const [subMenuArray, setSubMenuArray] = useState([]);
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_specialities = useSelector((state) => state.medwiki.all_specialities);
  const userDetailsState = useSelector((state) => state.common.user_details);

  const is_more_mobile = useSelector((state) => state.common.isMoreMobileOpen);
  const [currentMenu, setCurrentMenu] = useState('dashboard');
  const [showredirectModal, setShowredirectModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const { pathname } = useLocation();
  const userDetails = () => getLocalStorage('user', {});
  const userSpecialities = () =>
    userDetails()?.speciality ? JSON.parse(userDetails()?.speciality ?? '[]') : [];
  const [userSpeciality, setUserSpeciality] = useState(userSpecialities());

  useEffect(() => {
    if (getLocalStorage('user', false) && JSON.parse(getLocalStorage('user', {})?.menu)) {
      let menu = JSON.parse(getLocalStorage('user', {})?.menu);
      if (menu) {
        if ('menu' in menu) {
          // let menu = JSON.parse(userDetails.menu);
          console.log('menu', menu);
          // setMenuJson({
          //   // ...menu
          //   // static_menu: convertAllMenu(menu?.static_menu || []),
          //   // all_menu: convertAllMenu(menu?.all_menu || [])
          //   DEFAULT_MENU
          // });
        } else setMenuJson(DEFAULT_MENU);
      }
    }
    // else setMenuJson(DEFAULT_MENU);
  }, [pathname]);

  useEffect(() => {
    if (is_more_mobile) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [is_more_mobile]);
  //   useEffect(()=>{
  // alert("user details"+(userSpeciality))
  //   },[])
  const unionWidthDefinedSpecs = (allSpecialities, callback) => {
    if (userSpecialities()?.length > 0) {
      let userSpeciality = userSpecialities();
      for (let _spec of allSpecialities) {
        if (
          !userSpeciality
            ?.map((_s) => _s.master_specialities_id)
            ?.includes(_spec.master_specialities_id)
        ) {
          let { master_specialities_id, specialities_name } = _spec;
          userSpeciality.push({
            master_specialities_id,
            specialities_name
          });
        }
      }
      callback(userSpeciality);
    } else {
      callback(allSpecialities);
    }
  };

  useEffect(() => {
    if (userDetailsState != '') {
      if (current_menu?.toLowerCase()?.includes('dashboard')) {
        setCurrentMenu('dashboard');
      } else if (current_menu?.toLowerCase()?.includes('session')) {
        setCurrentMenu('session');
      } else if (current_menu?.toLowerCase()?.includes('spq')) {
        setCurrentMenu('spq');
      } else if (current_menu?.toLowerCase()?.includes('clinical')) {
        setCurrentMenu('clinical');
      } else if (current_menu?.toLowerCase()?.includes('gr')) {
        setCurrentMenu('grandround');
      } else if (
        current_menu?.toLowerCase()?.includes('ebook') ||
        current_menu?.toLowerCase()?.includes('epaper')
      ) {
        setCurrentMenu('ebook');
      } else if (current_menu?.toLowerCase()?.includes('channel')) {
        setCurrentMenu('channel');
      } else if (current_menu?.toLowerCase()?.includes('telemed')) {
        setCurrentMenu('telemed');
      } else if (current_menu?.toLowerCase()?.includes('discussrefer')) {
        setCurrentMenu('discussrefer');
      } else if (
        current_menu?.toLowerCase()?.includes('training') ||
        current_menu?.toLowerCase()?.includes('course')
      ) {
        setCurrentMenu('training');
      } else if (current_menu?.toLowerCase()?.includes('doctube')) {
        setCurrentMenu('doctube');
      } else if (current_menu?.toLowerCase()?.includes('speciality')) {
        setCurrentMenu('speciality');
      } else if (
        current_menu?.toLowerCase()?.includes('diagnosis') ||
        current_menu?.toLowerCase()?.includes('ddx')
      ) {
        setCurrentMenu('diagnosis');
      } else if (current_menu?.toLowerCase()?.includes('observership')) {
        setCurrentMenu('observership');
      } else if (current_menu && current_menu !== '') {
        setCurrentMenu(current_menu?.toLowerCase());
      }
    }
  }, [current_menu]);

  // function getActiveClass(path) {
  //   if (currentMenu !== '') {
  //     if (
  //       currentMenu?.toLowerCase().includes(path?.toLowerCase()) ||
  //       path?.toLowerCase().includes(currentMenu?.toLowerCase())
  //     ) {
  //       return 'active';
  //     }
  //   }
  // }

  function getActiveClass(path) {
    return currentMenu === path ? 'active' : '';
  }

  const hideBtnNav = () => {
    dispatch(toggleMoreMobile(false));
  };

  const postMenuConfirmation = (confirmation) => {
    const menuJson = getLocalStorage('user', false)
      ? JSON.parse(getLocalStorage('user', {})?.menu)
      : '';
    let objdata = {
      data: {
        ...menuJson,
        main: confirmation ? 'dnr' : 'home'
      }
    };
    if (confirmation) {
      setLocalStorage('main_menu', 'dnr');
    } else {
      setLocalStorage('main_menu', '');
    }
    dispatch(
      postMenu(objdata, (res) => {
        log('res', res);
        if (res != 'error') {
          dispatch(
            getUserDetails((res) => {
              // setUserDetails(res?.data);
              window.location.reload();
            })
          );
        }
      })
    );
  };
  useEffect(() => {
    if (all_specialities && all_specialities?.length > 0) {
      let specialities = all_specialities?.map((_a) => {
        let { master_specialities_id, specialities_name } = _a;
        return { master_specialities_id, specialities_name };
      });
      specialities.sort(function (a, b) {
        if (a.specialities_name < b.specialities_name) {
          return -1;
        }
        if (a.specialities_name > b.specialities_name) {
          return 1;
        }
        return 0;
      });
      unionWidthDefinedSpecs(specialities, (union) => {
        console.log('ALL_SPECIALITIES', union);
        setUserSpeciality(union);
      });
    }
  }, [all_specialities]);

  function getNavbarIcon(iconName, extraclass = '') {
    switch (iconName) {
      case 'HELP&SUPPORT':
        return <NewSupport customClass="icon-size-24" />;
      case 'HOME':
        return <HomeNew className={`icon-size-28 ${extraclass ? extraclass : ''}`} />;
      case 'KHUB':
        return <Khub className="icon-size-24" />;
      case 'CPD':
        return <Cpd className="icon-size-24" />;
      case 'RESOURCES':
        return <Resourses className="icon-size-24" />;
      case 'PRODUCT':
        return <Products className="icon-size-24" />;
      case 'PROFILE':
        return <ProfileNew className="icon-size-24" />;
      case 'LOGOUT':
        return <LogoutNew className="icon-size-24" />;
      default:
        return '';
    }
  }

  return (
    <>
      <Navbar
        fixed="bottom"
        className="mblFooter bg-white shadow p-0 navbar navbar-expand navbar-light fixed-bottom"
      >
        <ul className="row fw-medium fs-5 mblFooterUl ps-0 position-relative w-100 bg-white m-0 z-3 list-unstyled">
          <li className="col position-relative p-0 d-flex justify-content-center">
            <Nav.Link
              href="javascript:void(0)"
              className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black  gtm_cl_header_home ${getActiveClass(
                'contents'
              )}`}
              onClick={() => {
                if (userDetailsState != '') {
                  hideBtnNav();
                  redirectTo(changeUrl('/contents'));
                  setCurrentMenu('contents');
                } else {
                  dispatch(toggleFormFocus(true));
                  if (pathname != '/contents') {
                    dispatch(
                      toggleGlobalModal({
                        show: true,
                        message: 'Please login/register with us to continue using Medisamvad',
                        icon: (
                          <i
                            className="flaticon-lock-symbol  opacity-50"
                            style={{ fontSize: '3rem' }}
                          />
                        ),
                        size: 'sm'
                      })
                    );
                  }
                }
              }}
              id="mobileFooter_menu_session_anchor_text"
            >
              <span className="position-relative w-100 mb-2 d-block gtm_cl_header_home d-flex align-items-center justify-content-center">
                {getNavbarIcon('KHUB')}
              </span>
              K-Hub
            </Nav.Link>
          </li>
          <li className="col position-relative p-0 d-flex justify-content-center">
            <Nav.Link
              href="javascript:void(0)"
              className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black  gtm_cl_header_home ${getActiveClass(
                'sessions'
              )}`}
              onClick={() => {
                if (userDetailsState != '') {
                  hideBtnNav();
                  redirectTo(changeUrl('/sessions'));
                  setCurrentMenu('sessions');
                } else {
                  dispatch(toggleFormFocus(true));
                  if (pathname != '/sessions') {
                    dispatch(
                      toggleGlobalModal({
                        show: true,
                        message: 'Please login/register with us to continue using Medisamvad',
                        icon: (
                          <i
                            className="flaticon-lock-symbol  opacity-50"
                            style={{ fontSize: '3rem' }}
                          />
                        ),
                        size: 'sm'
                      })
                    );
                  }
                }
              }}
              id="mobileFooter_menu_session_anchor_text"
            >
              <span className="position-relative w-100 mb-2 d-block gtm_cl_header_home d-flex align-items-center justify-content-center">
                {getNavbarIcon('CPD')}
              </span>
              CPD
            </Nav.Link>
          </li>
          <li className="col position-relative p-0 d-flex justify-content-center">
            <Nav.Link
              href="javascript:void(0)"
              className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black more_mobile border border-5 border-white rounded-circle mt-n3 gtm_cl_header_home ${getActiveClass(
                'dashboard'
              )}`}
              onClick={() => {
                if (userDetailsState != '') {
                  hideBtnNav();
                  redirectTo(changeUrl('/'));
                  setCurrentMenu('dashboard');
                } else {
                  dispatch(toggleFormFocus(true));
                  if (pathname != '/') {
                    dispatch(
                      toggleGlobalModal({
                        show: true,
                        message: 'Please login/register with us to continue using Medisamvad',
                        icon: (
                          <i
                            className="flaticon-lock-symbol  opacity-50"
                            style={{ fontSize: '3rem' }}
                          />
                        ),
                        size: 'sm'
                      })
                    );
                  }
                }
              }}
              id="mobileFooter_menu_session_anchor_text"
            >
              <span className="position-relative w-100 mb-2 d-block gtm_cl_header_home d-flex align-items-center justify-content-center">
                {getNavbarIcon(
                  'HOME',
                  getActiveClass('dashboard') === 'active' ? 'fill-white' : ''
                )}
              </span>
              {/* Home */}
            </Nav.Link>
          </li>
          <li className="col position-relative p-0 d-flex justify-content-center">
            <Nav.Link
              href="javascript:void(0)"
              className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black  gtm_cl_header_home ${getActiveClass(
                'help&support'
              )}`}
              onClick={() => {
                if (userDetailsState != '') {
                  hideBtnNav();
                  redirectTo(changeUrl('/get-support'));
                  setCurrentMenu('help&support');
                } else {
                  dispatch(toggleFormFocus(true));
                  if (pathname != '/get-support') {
                    dispatch(
                      toggleGlobalModal({
                        show: true,
                        message: 'Please login/register with us to continue using Medisamvad',
                        icon: (
                          <i
                            className="flaticon-lock-symbol  opacity-50"
                            style={{ fontSize: '3rem' }}
                          />
                        ),
                        size: 'sm'
                      })
                    );
                  }
                }
              }}
              id="mobileFooter_menu_session_anchor_text"
            >
              <span className="position-relative w-100 mb-2 d-block gtm_cl_header_home d-flex align-items-center justify-content-center">
                {getNavbarIcon('HELP&SUPPORT')}
              </span>
              Get Support
            </Nav.Link>
          </li>
          <li className="col position-relative p-0 d-flex justify-content-center">
            <Nav.Link
              href="javascript:void(0)"
              className={`position-relative py-2 px-1 d-flex justify-content-center align-items-center flex-column text-black  gtm_cl_header_home ${getActiveClass(
                'products'
              )}`}
              onClick={() => {
                hideBtnNav();

                setShowredirectModal(true);
                setRedirectUrl('https://www.alkemlabs.com/our-offerings/rx-products');
              }}
              id="mobileFooter_menu_session_anchor_text"
            >
              <span className="position-relative w-100 mb-2 d-block gtm_cl_header_home d-flex align-items-center justify-content-center">
                {getNavbarIcon('PRODUCT')}
              </span>
              Products
            </Nav.Link>
          </li>
          {isIosApp() == true ? <li className={`col-12 iosSpecial-safe-area-bottom`}></li> : <></>}
          <RedirectModal
            show={showredirectModal}
            url={redirectUrl}
            onHide={() => {
              setShowredirectModal(false);
            }}
          />
        </ul>
      </Navbar>
    </>
  );
}

export default memo(MobileFooter);
